<template>
  <div class="GlobalFormDemo">
    <GlobalForm class="search-form"  :needBtnLoading="true" :init-data="initData" :form-rules="formRules" :form-item-list="formItemList"
      :inline="true" round :labelWidth="120" @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="入库单位" slot="supplierId" style="margin: 0" label-width="70px">
        <el-select v-model="supplierId" size="mini" placeholder="输入或选择单位名称查询" filterable clearable
          :filter-method="warehouseFilter"  @visible-change="visibleyouchange">
          <el-option v-for="item in merchantSelectList" :key="item.id" :label="item.exchangeUnitName"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="旧机串号" slot="orderPlole" style="margin: 0" label-width="70px">
        <el-input v-model="imei" :clearable="true" size="mini" placeholder="请输入旧机串号"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="订单号" slot="orderPlole1" style="margin: 0" label-width="60px">
        <el-input v-model="recycleOrderNo" :clearable="true" size="mini" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="旧机条码" slot="orderPlole2" style="margin: 0" label-width="70px">
        <el-input v-model="barCode" :clearable="true" size="mini" placeholder="请输入旧机条码"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="入库时间" slot="operationSelect" style="margin: 0" label-width="70px">
        <el-date-picker v-model="entryTime" :picker-options="pickerOptions" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="el_from" label="旧机名称" slot="phoneName" style="margin: 0" label-width="70px">
        <el-input v-model="phoneName" :clearable="true" size="mini" placeholder="请输入旧机名称查询"></el-input>
      </el-form-item>
    </GlobalForm>
    <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
      <el-button icon="el-icon-download" size="small" type="success" :loading="Excelloading" @click="exportExcel">导出Excel</el-button>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { MessageBox } from "element-ui";
import { http } from "../../../utils/request/newIndex";
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号码"));
  } else {
    callback();
  }
};
let checkPrice = (rule, value, callback) => {
  if (value < 50) {
    callback(new Error("扣款条件不能小于50元"));
  } else {
    callback();
  }
};
export default {
  name: "Form",
  props: {
  },
  data() {
    return {
      merchantSelectList: [],
      filtterSelectList: [],
      supplierId: "",
      initData: null,
      dialogVisible: false,
      recycleOrderNo: "",
      imei: "",
      companyId: "",
      barCode: "",
      phoneName:"",//旧机名称
      entryTime: null,
      companyMatchSelectList: [],
      allMerchantSelectList: [],
      formItemList: [{ slotName: "supplierId" }, { slotName: "orderPlole" }, { slotName: "orderPlole1" }, { slotName: "orderPlole2" },{slotName:'operationSelect'}, { slotName: "phoneName" }],
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      day60: 31 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
      SeachParams: {},
      formRules: {},
      startTime:"",
      endTime:"",
      butLoading: false,
      middleRegionVos: [],
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
      Excelloading:false,
    };
  },
  created() {
    this.handleConfirm();
    this.supplierSelect()
  },
  watch: {
    "modelform.matchChannelId"(val) {
      this.modelform.companyId = "";
      this.modelform.merchantId = "";

    },
    "modelform.companyId"(val) {
      this.modelform.merchantId = "";
    },
  },
  methods: {
    // 获取往来单位列表
    supplierSelect() {
      _api.supplierSelect({merchantId:this.$route.query.merchantId}).then(res => {
        if (res.code == 1) {
          this.merchantSelectList = JSON.parse(JSON.stringify(res)).data
          this.filtterSelectList = JSON.parse(JSON.stringify(res)).data
        }
      })
    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    handleConfirm(data,cd) {
      if (this.entryTime === null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      const SeachParams = {
        barCode: this.barCode,
        phoneName: this.phoneName,
        imei: this.imei,
        recycleOrderNo: this.recycleOrderNo,
        supplierId: this.supplierId,
        startTimeInto:this.startTime,
        endTimeInto:this.endTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    addMatchm() {
      this.dialogVisible = true;
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.companyMatchSelectList = []
      this.allMerchantSelectList = []
      this.dialogVisible = false;
    },
    // 确认添加
    handleSuccess() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.commandDisableShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加生效时间时间
    completionTimeFun(e) {
      console.log(e);
    },
    visibleyouchange(e) {
      if (e) {
        this.merchantSelectList = this.filtterSelectList;
      }
    },
    //出库单位筛选
    warehouseFilter(val) {
      console.log(val);
      // this.value = val //给绑定值赋值
      if (val) { //val存在
        this.merchantSelectList = this.filtterSelectList.filter((item) => {
          console.log(item);
          if (item.exchangeUnitName.indexOf(val) !==-1 || item.exchangeShortName.indexOf(val) !== -1 || item.exchangeShortName.toUpperCase().indexOf(val.toUpperCase()) !== -1) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.merchantSelectList = this.filtterSelectList;
      }
    },
  
    // 选择门店商家回调
    formCompanyChange(e) {
      console.log(e);
      this.getMiddleAdoptMerchantMatch();
    },
    exportExcel(e) {
      this.Excelloading = true;
      http.warehouseExcel({merchantId:this.$route.query.merchantId,...this.SeachParams}).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "在库旧机明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
        this.Excelloading = false;
      }).catch((err)=>{
        this.Excelloading = false;
      });
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.rowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.rowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.rowId.toString()) {
            this.middleRegionVos.splice(this.rowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.rowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 谷歌验证弹框确定
    commandDisableSure() {
      let params = {
        middleRegionVos: this.middleRegionVos,
        ...this.modelform,
        merchantId:this.$route.query.merchantId
      };
      this.butLoading = true;
      _api
        .addMatchTransaction(params)
        .then((res) => {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.commandDisableShow = false;
          this.handleConfirm(this.SeachParams);
          this.butLoading = false;
          this.middleRegionVos = [];
        })
        .catch(() => {
          this.butLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  // /deep/ .el-form--inline .el-form-item {
  //   margin-right: 20px !important;
  // }

  .search-form {
    /deep/.el-select>.el-input {
      width: 185px !important;
    }
  }

  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-select {
      width: 100%;
    }
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;
    font-size: 12px;

    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }

  .command {
    display: flex;
    align-items: center;

    /deep/.el-input {
      width: 300px;
    }
  }
}
</style>
