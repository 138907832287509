<template>
  <div class="class-list">
    <GlobalInfoBar
      title="在库旧机"
      content="管理或查找仓库在库旧机"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From/>
      </div>
      <Table/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      channelSelectList: [],
      merchantSelectList: [],
      allMerchantSelectList: []
    };
  },
  created() {
    
  },
  methods: {
  },
};
</script>

<style>
</style>