<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
    <el-table-column label="入库单据" slot="receipt" align="center">
        <template slot-scope="{ row }">
          <span  @click="juminto(row.intoNo)" style="color: #0981ff;cursor: pointer;text-decoration: underline;">{{ row.intoNo||'--'}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="采购价格（元）" slot="purchasePrice" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.purchasePrice||'--'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="库龄" slot="documents" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.age>29" style="color: red;">{{ row.age}}天</span>
          <span v-else>{{ row.age||0}}天</span>
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" slot="recycleOrderNo" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.recycleOrderNo||'--'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="门店商户" slot="orderCompanyName" align="center">
        <!-- <template slot-scope="{ row }">
          <span>{{ row.orderCompanyName||'--' }}</span>
        </template> -->
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.orderCompanyName&&row.isCurrentMerchantAck"
            class="item"
            effect="dark"
            :content="row.orderCompanyName"
            placement="top"
          >
            <div class="Remarks2">{{ row.orderCompanyName }}
            </div>
          </el-tooltip>
          <div class="Remarks2" style="color:red"  v-if="row.orderCompanyName&&!row.isCurrentMerchantAck">非您商户,无法查看
          </div>
          <span v-if="!row.orderCompanyName">--</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="text"
            @click="disableopen(row)"
            >
            串号跟踪明细
          </el-button>
        </template>
      </el-table-column> -->
    </GlobalTable>
    <!-- 动态口令弹出框 -->
    <el-dialog
      :title="commandTitle"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div class="enable-tip">
        <div>
          说明：关闭后不再向该回收商进行撮合交易扣款，包括渠道扣款和繁星扣款。
        </div>
        <div class="tip-sure">关闭后不可重新开启，是否确认关闭？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除提示"
      :visible.sync="dialogDelVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="dialogDelVisible = false"
    >
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandDialogClosed"
    >
      <div class="command2">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="modelform.command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandDisableSure"
          :loading="butLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
let checkPrice = (rule, value, callback) => {
  if (value < 50) {
    callback(new Error("扣款条件不能小于50元"));
  } else {
    callback();
  }
};
import {http} from "../../../utils/request/newIndex";
export default {
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],
      commandShow: false,
      butLoading: false,
      commandTitle: "",
      command: "",
      matchId: "",
      dialogVisible: false,
      takeEffectTimeIsEdit: false,
      rowId: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pageNum: 1,
      companySelectList: [],
      alreadyCompanyList: [],
      tableColumns: [
        { label: "旧机串号", prop: "imei" },
        { label: "旧机名称", prop: "phoneName" },
        { label: "所属入库单位", prop: "intoSupplierName" },
        { label: "入库仓库", prop: "intoWarehouseName" },
        {slotName:'receipt'},
        {slotName:'purchasePrice'}, // 采购价格
        {slotName:'documents'},
        { label: "旧机条码", prop: "barCode" },
        { slotName: "recycleOrderNo" },
        { slotName: "orderCompanyName" },
        { slotName: "operation" },
      ],
      matchChannelName: "",
      merchantName: "",
      companyName: "",
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
        },
      },
      middleButLoading: false,
      middleRegionVos: [],
      deductionColumns: [
        { slotName: "rank" },
        { label: "渠道扣款(次/收货次数)", prop: "channelTax" },
        { label: "繁星扣款(次/收货次数)", prop: "btstarsTax" },
        { slotName: "operation" },
      ],
      middleRowId: "",
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
      tableOperation: "",
      jumimei:""
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    "modelform.merchantId"(val) {
      if (!val) {
        this.modelform.companyId = "";
      }
    },
  },
  methods: {
    juminto(intoNo){
      this.$router.push({
        path:"/transferManagement/createOutbound",
        query:{
          intoNo,
          merchantId:this.$route.query.merchantId,
          merchantName:this.$route.query.merchantName
        }
      })
    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        barCode: this.SeachParams.barCode,
        phoneName: this.SeachParams.phoneName, // 旧机名称
        imei: this.SeachParams.imei,
        recycleOrderNo: this.SeachParams.recycleOrderNo,
        supplierId: this.SeachParams.supplierId,
        startTimeInto:this.SeachParams.startTimeInto,
        endTimeInto:this.SeachParams.endTimeInto,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        merchantId:this.$route.query.merchantId
      };
      this.loading = true;
      http.warehouseList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    disableopen(row) {
      console.log(row.imei);
      this.$router.push({path:'/inventoryManagement/stringTracking',query:{imei:row.imei}})
    },
    // 关闭弹框关闭
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    // 确认关闭弹框
    submit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.butLoading = true;
      let params = {
        id: this.matchId,
        command: this.command,
        merchantId:this.$route.query.merchantId
      };
      _api.closeMatchTransaction(params).then((res) => {
        this.$message.success(res.msg || "操作成功");
        this.butLoading = false;
        this.commandShow = false;
        this.command = "";
        this.handleCurrentChange();
      });
    },
    //
    editBtn(row) {
      this.rowId = row.id;
      this.dialogVisible = true;
      this.takeEffectTimeIsEdit = row.state == "01";
      console.log(new Date(row.takeEffectTime).getTime());
      this.modelform = {
        matchChannelId: row.matchChannelId,
        id: row.id,
        merchantId: row.merchantId,
        companyId: row.companyId,
        takeEffectTime: new Date(row.takeEffectTime).getTime(),
      };
      this.matchChannelName = row.matchChannelName;
      this.merchantName = row.merchantName;
      this.companyName = row.companyName;
      this.middleRegionVos = JSON.parse(JSON.stringify(row.regionDtoList));
    },
    // 获取门店商家
    getMatchCompanyList() {
      _api.matchCompanyList({ pageNum: 1, pageSize: 999 ,merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code === 1) {
          this.alreadyCompanyList = res.data;
        }
      });
    },
    // 确认修改
    handleSuccess() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.middleRegionVos.length) {
            this.$message.error("请设置扣款条件");
            return;
          }
          this.commandDisableShow = true;
          // let params = {
          //   // matchChannelId: this.modelform.matchChannelId,
          //   id: this.modelform.id,
          //   channelTax: this.modelform.channelTax,
          //   btstarsTax: this.modelform.btstarsTax,
          //   takeEffectPrice: this.modelform.takeEffectPrice,
          //   takeEffectTime: this.modelform.takeEffectTime,
          //   command: this.modelform.command,
          // };
          // _api.editMatchTransaction(params).then((res) => {
          //   this.$message.success("成功");
          //   this.dialogVisible = false;
          //   this.handleCurrentChange();
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.middleRowId.toString()) {
            this.middleRegionVos.splice(this.middleRowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.middleRowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 谷歌验证弹框确定
    commandDisableSure() {
      let params = {
        merchantId:this.$route.query.merchantId,
        middleRegionVos: this.middleRegionVos,
        ...this.modelform,
      };
      this.middleButLoading = true;
      _api
        .editMatchTransaction(params)
        .then((res) => {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.handleCurrentChange();
          this.commandDisableShow = false;
          this.middleButLoading = false;
          this.middleRegionVos = [];
        })
        .catch(() => {
          this.middleButLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/.el-button{
  >span{
   text-decoration: underline;
  }
}
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }
  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-select {
      width: 100%;
    }
  }
  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }
    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }
  .del-title {
    color: #ff8080;
    font-size: 12px;
    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }
  .command2 {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex1 {
    flex: 1;
  }
  .overflow-hiding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }
}
</style>
